import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import './policyHolderTab.scss';
import { Grid } from '@mui/material';
import useCountry from '../../../../shared/utils/useCountry';

const PolicyHolderTab = ({ policyDetails }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  const personalInformationFields = [
    {
      name: 'civility',
      label: t('POLICY_DETAILS.CUSTOMER.civility'),
    },
    {
      name: 'email',
      label: t('POLICY_DETAILS.CUSTOMER.email'),
    },
    {
      name: 'firstName',
      label: t('POLICY_DETAILS.CUSTOMER.firstName'),
    },
    {
      name: 'phone',
      label: t('POLICY_DETAILS.CUSTOMER.phone'),
    },
    {
      name: 'lastName',
      label: t('POLICY_DETAILS.CUSTOMER.lastName'),
    },
    {
      name: 'state',
      label: t('POLICY_DETAILS.CUSTOMER.state'),
    },
    {
      name: 'birthDate',
      label: t('POLICY_DETAILS.CUSTOMER.birthDate'),
    },
  ].filter((field) => {
    if (field.name === 'state' && values.country?.toLowerCase() === 'nz') {
      return false;
    }
    return true;
  });

  const { stateName, dialCode, humanizePhoneNumber } = useCountry(
    values.country,
    values.state,
  );

  useEffect(() => {
    if (policyDetails) {
      const {
        civility,
        email,
        firstName,
        phone,
        lastName,
        birthDate,
        country,
      } = policyDetails.subscriber;
      const dobDate = birthDate ?? policyDetails.beneficiaries[0].birthDate;
      const initialValues = {
        civility,
        firstName,
        lastName,
        birthDate: format(new Date(dobDate), 'dd/MM/yyyy'),
        email,
        phone: humanizePhoneNumber(phone),
        country,
        state: stateName || policyDetails.policy.genericData.state,
      };

      setValues(initialValues);
    }
  }, [policyDetails, dialCode]);

  return (
    <div className="policyHolderTab">
      <div className="header">
        <h3>{t('POLICY_DETAILS.tabPolicyHolder')}</h3>
      </div>

      <Grid container spacing={2}>
        {personalInformationFields.map((field, index) => (
          <Grid item xs={12} md={6} key={index} className="field-container">
            <div className={'label-text'}>{field.label}</div>
            <div className="text-value">
              {values[field.name] || t('No data')}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PolicyHolderTab.propTypes = {
  policyDetails: PropTypes.shape({
    bookingDataId: PropTypes.string,
    subscriber: PropTypes.shape({
      civility: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      birthDate: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      country: PropTypes.string,
    }),
    beneficiaries: PropTypes.arrayOf(PropTypes.object),
    policy: PropTypes.shape({
      policyNumber: PropTypes.string,
      status: PropTypes.shape({
        label: PropTypes.string,
      }),
      genericData: PropTypes.shape({
        marketingOffers: PropTypes.string,
        state: PropTypes.string,
      }),
    }),
    insuranceData: PropTypes.shape({
      marketingOffers: PropTypes.bool,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
};

export default PolicyHolderTab;
