function createErrorLoggingService() {
    /** @type {boolean} */
    const isDevelopmentEnvironment = localStorage.getItem('debugMode') === 'true'

    /**
     * @param {string} message
     * @param {Error|undefined|null|*} error
     */
    const log = (message, error) => {
        if (isDevelopmentEnvironment) {
            console.log('Error Message:', message);
            if (error) {
                console.error('Error Details:', error);
            }
        }
    }

    return {
        log
    }
}

/**
 *  @returns {{
 *     log: (message: string, error?: Error | undefined | null) => void
 *   }}
 */
const ErrorLoggingService = (() => {
    let instance = null;
    return () => {
        if (!instance) {
            instance = createErrorLoggingService();
        }
        return instance;
    };
})();

export default ErrorLoggingService;
