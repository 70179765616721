import { getBrandNameForInpathPolicy } from './inpath.helper';

const getPolicyChannel = (channelName) => {
  switch (channelName) {
    case 'INPATH':
      return 'INPATH';
    case 'WHITELABEL':
      return 'WL';
    case 'RETAIL_AGENTPORTAL':
    case 'RETAIL_HELIO':
      return 'RETAIL';
    default:
      throw new Error('Unknown channel spotted');
  }
};

const getPolicyCountry = (subscriberCountry) => {
  if (!subscriberCountry) {
    throw new Error('Unknown subscriber country');
  }

  const country = subscriberCountry.toLowerCase();

  if (country === 'au') {
    return null;
  }
  return country;
};

export const getBrandFromModifiedBooking = (bookingResponse) => {
  const brandOfPolicy = bookingResponse.bookingData.proxy.brandCode;
  const channelOfPolicy = getPolicyChannel(bookingResponse.bookingData.channel);
  const countryOfPolicy = getPolicyCountry(
    bookingResponse?.customer.countryCode,
  );

  return `${brandOfPolicy}_${channelOfPolicy}${
    countryOfPolicy ? `_${countryOfPolicy}` : ''
  }`;
};
