import React, { useEffect, useContext } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import useFetchFAQsData from './useFetchFAQsData';
import FAQItem from './components/FAQItem';
import SoftBox from '../../../components/SoftBox';
import { UserDataContext } from 'shared/context/userDataContext';


const FAQ = () => {
  const { t } = useTranslation();
  const { selectedBusinessUnit } = useContext(UserDataContext)
  const { data, fetchData } = useFetchFAQsData();

  useEffect(() => {
    fetchData();
  }, [selectedBusinessUnit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('FAQ.title')}
        </SoftTypography>
        <SoftBox data-testid="FAQ">
          <Grid container ml={0} pr={15}>
            <Grid item xs={12}>
              {data && data.length > 0 ? (
                data.map((faq, index) => <FAQItem faq={faq} key={index} />)
              ) : (
                <Typography>{t('FAQ.noneAvailable')}</Typography>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

FAQ.propTypes = {};

FAQ.defaultProps = {};

export default FAQ;
