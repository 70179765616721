import React, {useContext, useEffect} from 'react';
import DashboardNavbar from '../../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../../examples/LayoutContainers/DashboardLayout';
import { Grid, Container, Link } from '@mui/material';
import useFetchTravelAlertsData from '../useFetchTravelAlertsData';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import typography from '../../../../assets/theme/base/typography';
import TravelAlertCard from './components/TravelAlertCard';
import SoftBox from '../../../../components/SoftBox';
import {WelcomePageConfigContext} from "../../../../shared/context/welcome-page-config-context";
import ErrorLoggingService from "../../../../shared/services/errorlogging.service";
import { UserDataContext } from 'shared/context/userDataContext';

const TravelAlerts = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFetchTravelAlertsData();
  const { h2 } = typography;
  const { selectedBusinessUnit } = useContext(UserDataContext)

  const {travelAlertsLink, isConfigLoading, travelAlertsLinkText} = useContext(WelcomePageConfigContext);
  const errorLoggingService = ErrorLoggingService();

  useEffect(() => {
    if (isConfigLoading) return;

    if (!travelAlertsLink) {
      errorLoggingService.log('travelAlertsLink is not provided -- fill travelAlertsLink in config [eshop brand]');
    }

    if (!travelAlertsLinkText) {
      errorLoggingService.log('travelAlertsLinkText is not provided -- fill travelAlertsTextLink in config [eshop brand]');
    }
  }, [isConfigLoading, travelAlertsLink, travelAlertsLinkText])

  useEffect(() => {
    fetchData();
  }, [selectedBusinessUnit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('TRAVEL_ALERTS.title')}
        </SoftTypography>
        <SoftTypography sx={{ marginBottom: '1rem' }}>
          {t('TRAVEL_ALERTS.instruction1')}{' '}
          <Link
            href="contact-us#medical&travelemergencies"
            sx={{
              color: '#0a76ec',
            }}
          >
            {t('TRAVEL_ALERTS.EAMedicalTeam')}
          </Link>
        </SoftTypography>
        <SoftTypography sx={{ marginBottom: '2rem' }}>
          {t('TRAVEL_ALERTS.instruction2-1')}{' '}
          <a
            href={travelAlertsLink || ''}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0a76ec' }}
          >
            {travelAlertsLinkText}
          </a>{' '}
          {t('TRAVEL_ALERTS.instruction2-2')}
        </SoftTypography>
        <SoftBox data-testid="TravelAlerts">
          <Grid container ml={0} pr={15}>
            <Grid item xs={12}>
              {data && data.length > 0 ? (
                data.map((travelAlert, index) => (
                  <TravelAlertCard travelAlert={travelAlert} key={index} />
                ))
              ) : (
                <SoftTypography>
                  {t('TRAVEL_ALERTS.noneAvailable')}
                </SoftTypography>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

TravelAlerts.propTypes = {};

TravelAlerts.defaultProps = {};

export default TravelAlerts;
