import { getLocaleByCurrency } from '../../helpers/currency.helper';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CurrencyComponent = ({ value, countryCode, showCountryCode = false }) => {
  const { t } = useTranslation();

  if (value === '1000000') {
    return t('POLICY_DETAILS.unlimited');
  }

  if (countryCode) {
    const formattedCurrency = new Intl.NumberFormat(
      getLocaleByCurrency(countryCode),
      {
        style: 'currency',
        currency: countryCode,
      },
    ).format(value);
    return showCountryCode
      ? `${formattedCurrency} ${countryCode}`
      : formattedCurrency;
  }
  return value;
};

CurrencyComponent.propTypes = {
  value: PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default CurrencyComponent;
