import React, { useEffect, useContext } from 'react';
import DashboardNavbar from '../../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../../examples/LayoutContainers/DashboardLayout';
import { Grid, Container } from '@mui/material';
import useFetchImportantUpdatesData from '../useFetchImportantUpdatesData';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import UpdateCard from './components/UpdateCard';
import SoftBox from '../../../../components/SoftBox';
import { UserDataContext } from 'shared/context/userDataContext';

const ImportantUpdates = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFetchImportantUpdatesData();
  const { selectedBusinessUnit } = useContext(UserDataContext)

  useEffect(() => {
    fetchData();
  }, [selectedBusinessUnit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('IMPORTANT_UPDATES.title')}
        </SoftTypography>
        <SoftBox data-testid="ImportantUpdates">
          <Grid container ml={0} pr={15}>
            <Grid item xs={12}>
              {data && data.length > 0 ? (
                data.map((update, index) => (
                  <UpdateCard update={update} key={index} />
                ))
              ) : (
                <SoftTypography>
                  {t('IMPORTANT_UPDATES.noneAvailable')}
                </SoftTypography>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

ImportantUpdates.propTypes = {};

ImportantUpdates.defaultProps = {};

export default ImportantUpdates;
