import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './pending-quotes-table.scss';
import { IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import GenericTable from 'shared/components/GenericTable/genericTable';
import { format } from 'date-fns';
import { CONFIG_APP } from '../../../../shared/const/app.config';
import CurrencyComponent from '../../../../shared/components/Currency/Currency';
import { UserDataContext } from 'shared/context/userDataContext';

const PendingQuotesTable = ({ data, tableUuid }) => {
  const { t } = useTranslation();
  const { userData } = useContext(UserDataContext);
  const path = 'HOME.PENDING_QUOTES.table.header';

  const openQuote = (row) => {
    if (!userData.quotesLocked) {
      const url = `/quote?agent=true&quoteId=${row.quoteId}`;
      window.location.href = url;
    }
  };

  const columns = [
    {
      label: t(`${path}.bookingReference`),
      value: (row) => row.externalId,
    },
    {
      label: `${t(`${path}.quoteId`)}`,
      value: (row) => `${row.quoteId}`,
    },
    {
      label: t(`${path}.customer`),
      value: (row) => {
        const firstName = row.customerFirstName || '';
        const lastName = row.customerLastName || '';
        return `${firstName} ${lastName}`;
      },
    },
    {
      label: t(`${path}.product`),
      value: (row) => row.productName,
    },
    {
      label: t(`${path}.destination`),
      value: (row) => row.destination,
    },
    {
      label: t(`${path}.dateCreated`),
      // TODO: Send a correct format from BE
      value: (row) => format(new Date(row.createdDate.replace(/Z$/, '')), CONFIG_APP.DATE_FORMAT),
    },
    {
      label: t(`${path}.exp`),
      value: (row) => (
        <span
          style={{
            color:
              row.expiresInDays <= 3
                ? 'red'
                : row.expiresInDays >= 10
                ? 'green'
                : 'black',
          }}
        >
          {row.expiresInDays}{' '}
          <span style={{ display: row.expiresInDays ? 'inline' : 'none' }}>
            {t('HOME.PENDING_QUOTES.table.days')}
          </span>
        </span>
      ),
      width: '70px',
    },
    {
      label: t(`${path}.value`),
      value: (row) => (
        <CurrencyComponent
          value={row.amount.amount}
          countryCode={row.amount.currency}
        ></CurrencyComponent>
      ),
    },
    ...(!userData.quotesLocked
      ? [
          {
            label: t(`${path}.view`),
            value: () => (
              <IconButton size="small" sx={{ color: '#0a76ec' }}>
                <PlayCircleIcon />
              </IconButton>
            ),
            width: '70px',
          },
        ]
      : []),
  ];

  return (
    <GenericTable
      data={data}
      columns={columns}
      path={path}
      onRowClick={openQuote}
      tableUuid={tableUuid}
    />
  );
};

PendingQuotesTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableUuid: PropTypes.string,
};

PendingQuotesTable.defaultProps = {};

export default PendingQuotesTable;
